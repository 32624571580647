import styles from './ManageComponentsPage.module.scss'
import {useEffect, useState} from "react";
import {
  ComponentDetails, EventLogItem,
  NotifyChainDetails,
  NotifyChainRequest,
  StateHistoryDetails,
  UserDetails
} from "@perentie/common";
import {perDS} from "../../datasource/WebDS";
import NotifyChainComponent from "./NotifyChainComponent";
import {Button, Cell, Row, Table} from "@systemic-design-framework/react";
import StateHistoryComponent from "./StateHistoryComponent";
import Tabs from '../../components/Tab/Tabs';

interface ComponentCardProps {
  details: ComponentDetails;
}
function ComponentCard(props:ComponentCardProps){
  const {details} = props;
  return (
    <div className={styles["compDetails"]}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div className={styles["component-info"]} style={{}}>
          <div className={styles["component-info-key"]} style={{}}>
            <span>Name</span>
          </div>
          <div className={styles["component-info-val"]}>
            <span>{details?.name}</span>
          </div>
        </div>
        <div className={styles["component-info"]} style={{}}>
          <div className={styles["component-info-key"]} style={{}}>
            <span>State</span>
          </div>
          <div className={`${styles["component-info-val"]} ${styles["component-info-val-clickable"]}`}>
            <span>{details?.state}</span>
          </div>
        </div>
        <div className={styles["component-info"]} style={{}}>
          <div className={styles["component-info-key"]} style={{}}>
            <span>Description</span>
          </div>
          <div className={`${styles["component-info-val"]}`}>
            <span>{details?.description}</span>
          </div>
        </div>
        <div className={styles["component-info"]} style={{}}>
          <div className={styles["component-info-key"]} style={{}}>
            <span>System Status</span>
          </div>
          <div className={`${styles["component-info-val"]}`}>
            <span>{details?.systemStatus}</span>
          </div>
        </div>
        <div className={styles["component-info"]} style={{}}>
          <div className={styles["component-info-key"]} style={{}}>
            <span>Sticky Alerts</span>
          </div>
          <div className={styles["component-info-val"]}>
            <span>{details?.stickyAlerts + ''}</span>
          </div>
        </div>
        <div className={styles["component-info"]} style={{}}>
          <div className={styles["component-info-key"]} style={{}}>
            <span>Identifier</span>
          </div>
          <div className={styles["component-info-val"]}>
            <span>{details?.systemIdentifier}</span>
          </div>
        </div>
        <div className={styles["component-info"]} style={{}}>
          <div className={styles["component-info-key"]} style={{}}>
            <span>Agent</span>
          </div>
          <div className={styles["component-info-val"]}>
            <span>{details.agent?.name} ({details.agent?.objectId}) [{details.agent?.type}]</span>
          </div>
        </div>
      </div>
    </div>
  )
}

function ManageComponentsPage() {

  const [comps, setComps] = useState<ComponentDetails[]>([])
  const [compMap, setCompMap] = useState<Map<string, ComponentDetails>>(new Map());
  const [selectedOption, setSelectedOption] = useState('none');
  const [compDetails, setCompDetails] = useState<ComponentDetails | null | undefined>(null);
  const [notifyChain, setNotifyChain] = useState<NotifyChainDetails | null | undefined>(null);
  const [editingNotifyChain, setEditingNotifyChain] = useState(false);
  const [req, setReq] = useState<NotifyChainRequest | null>(null);
  const [stateHistory, setStateHistory] = useState<StateHistoryDetails[]>([]);
  const [events, setEvents] = useState<EventLogItem[]>([]);
  const [users, setUsers] = useState<UserDetails[]>([]);

  useEffect(() => {
    perDS.userGetAllComponents().then(res => {
      let nextMap = new Map<string,ComponentDetails>();
      res.forEach(cd => nextMap.set(cd.objectId!, cd));
      setComps(res);
      setCompMap(nextMap);
    }).catch( reason => {
      console.log(' getAllComponents failed', reason);
    });
  }, []);

  useEffect(() => {
    perDS.getPerentieUsers().then( res => {
      setUsers(res);
    }).catch( reason => {
      console.log(' getPerentieUsers failed', reason);
    });
  }, [])

  const optChanged = (item: any) => {
    setSelectedOption(item.target.value);
    setReq(null);
    if (item.target.value === 'none'){
      setCompDetails(null);
      setStateHistory([]);
    }else{
      const compId = item.target.value;
      setCompDetails(compMap.get(compId));
      perDS.getComponentNotifyChain(compId).then( res => {
        setNotifyChain(res);
      }).catch( reason => {
        console.log(' get notifychain catch', reason);
      });
      perDS.getComponentStateHistory(compId).then( res => {
        console.log(' state history', res);
        setStateHistory(res);
      }).catch( reason => {
        console.log(' getComponentStateHistory catch', reason);
      });
      perDS.getEventsSinceLastAlert(compId).then(res => {
        setEvents(res);
      }).catch(reason => {
        console.log(' getEventsSinceLastAlert catch', reason);
      })
    }
  }

  const options = comps.sort((a, b) => {
         return a.name != null && b.name != null ? a.name.localeCompare(b.name) : 1;
     }).map(c => {
         return <option key={c.objectId} value={c.objectId}>{c.name}</option>
     })

  const [historyItems, setHistoryItems] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const items = stateHistory.map(item => {
      return <StateHistoryComponent key={item.objectId} historyItem={item} />;
    });
    setHistoryItems(items);
  }, [stateHistory]);

  const handleNotifyChainChange = (newChain: NotifyChainDetails) => {
    const nextReq: NotifyChainRequest = {
      componentId: notifyChain?.componentId,
      links: newChain.links
    };
    setReq(nextReq);
    setNotifyChain(newChain);
  }

  const confirmClick = () => {
    setEditingNotifyChain(false);
    if (!!req){
      perDS.updateComponentNotifyChain(compDetails?.objectId!, req).then( res => {
        console.log(' update result', res);
        setReq( null);
        setNotifyChain(res);
      }).catch( reason => {
        console.log(' update failed', reason);
      });
    }
  }

  const cancelClick = () => {
    setReq(null);
    setEditingNotifyChain(false);
    perDS.getComponentNotifyChain(compDetails?.objectId!).then( res => {
      setNotifyChain(res);
    }).catch( reason => {
      console.log(' get notifychain catch', reason);
    });
  }


  const renderItem = (item: EventLogItem, index: number) =>{
    return (
      <Row key={item.objectId} striped={index % 2 !=0}>
        <Cell>{item.timestamp?.toLocaleString()}</Cell>
        <Cell>{item.type}{(item.type === "EVENT" ? ' - ' + item.tag : '')}</Cell>
        <Cell>{item.title}</Cell>
      </Row>
    )
  }

  const TableHeader = () => {
    return (
      <Row>
        <Cell>Time</Cell>
        <Cell>Type</Cell>
        <Cell>Info</Cell>
      </Row>
    );
  };

  const allLevelsHaveUsers = notifyChain?.links?.every(link => Object.keys(link.userMap!).length > 0);

  return (
    <div className={styles['content']}>
      <div>
        <label style={{marginRight: 8}}>Details for Component</label>
        <select onChange={optChanged} value={selectedOption}>
          <option value={'none'}>Select Component..</option>
          {options}
        </select>
      </div>

      {compDetails && (
        <div style={{display: 'flex', flexDirection: 'row', width: '100%', gap: 12, height: '100%'}}>
          <div style={{width: '40%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
              <Tabs style={{height: '50%'}} tabs={[
                {
                  label: "Component Details",
                  content:
                  <>
                    <div>
                      <ComponentCard details={compDetails}/>
                    </div>
                  </>
                }
              ]}/>

              <div style={{height: '50%', paddingBottom: 16}}>
                <div style={{borderTop: '1px solid #ccc'}}></div>
                <h4 style={{marginTop: 12, marginBottom: 8}}>Notify Chain</h4>
                <div style={{display: 'inline-flex', alignItems: 'center', columnGap: 16, marginBottom: 8}}>
                    {editingNotifyChain && <Button size={'s'} title={'Cancel'} variant='secondary-dark' onClick={cancelClick}/>}
                    {editingNotifyChain && <Button size={'s'} title={'Confirm'} onClick={confirmClick} disabled={!allLevelsHaveUsers}/>}
                    {!editingNotifyChain && <Button size={'s'} title={'Edit'} onClick={() => setEditingNotifyChain(true)}/>}
                  </div>
                <div style={{height: '80%', overflowY: 'auto', overflowX: 'hidden'}}>
                  <NotifyChainComponent changeNotifyChain={handleNotifyChainChange} chain={notifyChain} editing={editingNotifyChain} users={users}/>
                </div>
              </div>
          </div>

          <div style={{width: '60%'}}>
            <Tabs style={{paddingRight: 26, height: '100%'}} tabs={[
              {
                label: "Recent State History",
                content:
                <div style={{marginBottom: 76, display: 'flex', flexDirection: 'column', gap: 6}}>
                  {historyItems}
                </div>,
                contentStyle: {height: '100%', overflow: 'auto'}
              },
              {
                label: "Events Since Last Alert",
                content:
                <>
                  {events.length > 0 &&
                    <div style={{marginBottom: 76}}>
                      <Table data={events.sort(
                          (a,b) => {
                              return a.timestamp != null && b.timestamp != null ? b.timestamp!.getTime() - a.timestamp!.getTime() : 1;
                          })
                          } renderItem={renderItem}
                            HeaderComponent={<TableHeader/>} border/>
                    </div>
                  }
                </>,
                contentStyle: {height: '100%', overflow: 'auto'}
              }
            ]} />
          </div>
        </div>
      )}
    </div>
  )
}

export default ManageComponentsPage;
