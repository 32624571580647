/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgentBasicDetails } from './AgentBasicDetails';
import {
    AgentBasicDetailsFromJSON,
    AgentBasicDetailsFromJSONTyped,
    AgentBasicDetailsToJSON,
} from './AgentBasicDetails';

/**
 * 
 * @export
 * @interface ComponentDetails
 */
export interface ComponentDetails {
    /**
     * 
     * @type {string}
     * @memberof ComponentDetails
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetails
     */
    systemIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetails
     */
    description?: string;
    /**
     * 
     * @type {AgentBasicDetails}
     * @memberof ComponentDetails
     */
    agent?: AgentBasicDetails;
    /**
     * 
     * @type {Array<ComponentDetails>}
     * @memberof ComponentDetails
     */
    children?: Array<ComponentDetails>;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetails
     */
    state?: ComponentDetailsStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentDetails
     */
    stickyAlerts?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetails
     */
    rosterId?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetails
     */
    rosterName?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetails
     */
    systemStatus?: ComponentDetailsSystemStatusEnum;
}


/**
 * @export
 */
export const ComponentDetailsStateEnum = {
    Unknown: 'UNKNOWN',
    Ok: 'OK',
    Info: 'INFO',
    Warning: 'WARNING',
    Severe: 'SEVERE',
    Critical: 'CRITICAL'
} as const;
export type ComponentDetailsStateEnum = typeof ComponentDetailsStateEnum[keyof typeof ComponentDetailsStateEnum];

/**
 * @export
 */
export const ComponentDetailsSystemStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type ComponentDetailsSystemStatusEnum = typeof ComponentDetailsSystemStatusEnum[keyof typeof ComponentDetailsSystemStatusEnum];


/**
 * Check if a given object implements the ComponentDetails interface.
 */
export function instanceOfComponentDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComponentDetailsFromJSON(json: any): ComponentDetails {
    return ComponentDetailsFromJSONTyped(json, false);
}

export function ComponentDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'systemIdentifier': !exists(json, 'systemIdentifier') ? undefined : json['systemIdentifier'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'agent': !exists(json, 'agent') ? undefined : AgentBasicDetailsFromJSON(json['agent']),
        'children': !exists(json, 'children') ? undefined : ((json['children'] as Array<any>).map(ComponentDetailsFromJSON)),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'stickyAlerts': !exists(json, 'stickyAlerts') ? undefined : json['stickyAlerts'],
        'rosterId': !exists(json, 'rosterId') ? undefined : json['rosterId'],
        'rosterName': !exists(json, 'rosterName') ? undefined : json['rosterName'],
        'systemStatus': !exists(json, 'systemStatus') ? undefined : json['systemStatus'],
    };
}

export function ComponentDetailsToJSON(value?: ComponentDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectId': value.objectId,
        'name': value.name,
        'systemIdentifier': value.systemIdentifier,
        'description': value.description,
        'agent': AgentBasicDetailsToJSON(value.agent),
        'children': value.children === undefined ? undefined : ((value.children as Array<any>).map(ComponentDetailsToJSON)),
        'state': value.state,
        'stickyAlerts': value.stickyAlerts,
        'rosterId': value.rosterId,
        'rosterName': value.rosterName,
        'systemStatus': value.systemStatus,
    };
}

